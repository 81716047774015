import React, { useEffect, useState } from 'react';
import {
  Collapse,
  Tabs,
  Table,
  Tag,
  Typography,
  Spin,
  Card,
  Pagination,
  Tooltip,
  Row,
  Col,
  Button,
  Switch,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../constants/routePaths';
import './ComplianceEscalations.css';
import Title from 'antd/es/typography/Title';
import { getComplianceMetrics } from '../../api/dashboard.api';
import { InfoCircleOutlined } from '@ant-design/icons';

interface KeywordCall {
  callId: string;
  timestamp: number;
  sentence: string;
  start: number;
  end: number;
  score: number;
}

interface KeywordData {
  keyword: string;
  occurrences: number;
  calls: KeywordCall[];
}

interface ComplianceEscalationResponse {
  highAlertKeywords: KeywordData[];
  negativeKeywords: KeywordData[];
  agentZtpInfo: any[];
}

const { TabPane } = Tabs;
const { Text } = Typography;
const { Panel } = Collapse;

const ComplianceDashboard: React.FC<ComplianceEscalationResponse> = () => {
  const [escalations, setEscalations] = useState<ComplianceEscalationResponse>({
    highAlertKeywords: [],
    negativeKeywords: [],
    agentZtpInfo: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [showCurrentMonth, setShowCurrentMonth] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentOccurrencesPage, setCurrentOccurrencesPage] = useState(1);
  const [currentZtpPage, setCurrentZtpPage] = useState(1);
  const itemsPerPage = 5;
  const occurrencesPerPage = 5;
  const ztpItemsPerPage = 5;
  const [activeHighAlertPanel, setActiveHighAlertPanel] = useState<string | undefined>();
  const [activeNegativePanel, setActiveNegativePanel] = useState<string | undefined>();

  const fetchMetrics = async (isCurrentMonth: boolean) => {
    try {
      setIsLoading(true);
      const data = await getComplianceMetrics(isCurrentMonth);
      setEscalations(data);
    } catch (error) {
      console.error('Error fetching compliance escalations:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMetrics(showCurrentMonth);
  }, [showCurrentMonth]);

  useEffect(() => {
    if (escalations.highAlertKeywords.length > 0 && !activeHighAlertPanel) {
      setActiveHighAlertPanel(escalations.highAlertKeywords[0].keyword);
    }
  }, [escalations.highAlertKeywords, activeHighAlertPanel]);

  useEffect(() => {
    if (escalations.negativeKeywords.length > 0 && !activeNegativePanel) {
      setActiveNegativePanel(escalations.negativeKeywords[0].keyword);
    }
  }, [escalations.negativeKeywords, activeNegativePanel]);

  const keywordTableColumns: ColumnsType<KeywordCall> = [
    {
      title: 'Sentence',
      dataIndex: 'sentence',
      key: 'sentence',
      width: '50%',
      render: (sentence: string) => {
        const displayText = sentence.length > 50 ? `${sentence.substring(0, 50)}...` : sentence;
        return <Tooltip title={sentence}>{displayText}</Tooltip>;
      },
    },
    {
      title: 'Confidence',
      dataIndex: 'score',
      key: 'score',
      width: '15%',
      render: (score: number) => {
        const percentage = Math.round(score * 100);
        const color = percentage >= 80 ? 'green' : percentage >= 60 ? 'orange' : 'red';
        return <Tag color={color}>{percentage}%</Tag>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: '15%',
      render: (_, record) => (
        <Link to={`${RoutePath.CALL_ANALYSIS}?callId=${record.callId}`}>View Call</Link>
      ),
    },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleOccurrencesPageChange = (page: number) => {
    setCurrentOccurrencesPage(page);
  };

  const handleZtpPageChange = (page: number) => {
    setCurrentZtpPage(page);
  };

  const handleMonthToggle = (checked: boolean) => {
    setShowCurrentMonth(checked);
  };

  const paginatedKeywords = (keywords: KeywordData[]) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return keywords.slice(startIndex, startIndex + itemsPerPage);
  };

  const paginatedCalls = (calls: KeywordCall[]) => {
    const startIndex = (currentOccurrencesPage - 1) * occurrencesPerPage;
    return calls.slice(startIndex, startIndex + occurrencesPerPage);
  };

  const paginatedZtpInfo = (ztpInfo: any[]) => {
    const startIndex = (currentZtpPage - 1) * ztpItemsPerPage;
    return ztpInfo.slice(startIndex, startIndex + ztpItemsPerPage);
  };

  const renderKeywordSection = (keywords: KeywordData[], isHighAlert: boolean) => {
    const totalPages = Math.ceil(keywords.length / itemsPerPage);
    const displayedKeywords = paginatedKeywords(keywords);

    return (
      <>
        <Collapse
          activeKey={isHighAlert ? activeHighAlertPanel : activeNegativePanel}
          onChange={(key) =>
            isHighAlert
              ? setActiveHighAlertPanel(key as unknown as string)
              : setActiveNegativePanel(key as unknown as string)
          }
          accordion
        >
          {displayedKeywords.map((keyword) => {
            const totalOccurrencesPages = Math.ceil(keyword.calls.length / occurrencesPerPage);
            const displayedCalls = paginatedCalls(keyword.calls);

            return (
              <Panel
                key={keyword.keyword}
                header={
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Text strong>{keyword.keyword}</Text>
                    <Tag color="blue">{keyword.occurrences} occurrences</Tag>
                  </div>
                }
              >
                <Table
                  columns={keywordTableColumns}
                  dataSource={displayedCalls}
                  pagination={false}
                  rowKey={(record) => `${record.callId}-${record.start}`}
                  size="small"
                />
                <Pagination
                  current={currentOccurrencesPage}
                  pageSize={occurrencesPerPage}
                  total={keyword.calls.length}
                  onChange={handleOccurrencesPageChange}
                  style={{ marginTop: 16, textAlign: 'center' }}
                  showSizeChanger={false}
                />
              </Panel>
            );
          })}
        </Collapse>
        <Pagination
          current={currentPage}
          pageSize={itemsPerPage}
          total={keywords.length}
          onChange={handlePageChange}
          style={{ marginTop: 16, textAlign: 'center' }}
        />
      </>
    );
  };

  const ztpTableColumns = [
    {
      title: 'ZTP Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      render: (remarks: string) => {
        const displayText = remarks.length > 100 ? `${remarks.substring(0, 100)}...` : remarks;
        return (
          <Tooltip title={remarks}>
            <span>{displayText}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'isFatal',
      key: 'isFatal',
      render: (isFatal: boolean) => <Tag color="red">Unprofessional</Tag>,
    },
    {
      dataIndex: 'callId',
      key: 'callId',
      render: (callId: string) => (
        <Link to={`${RoutePath.CALL_ANALYSIS}?callId=${callId}`}>View Call</Link>
      ),
    },
  ];

  const renderZtpInfo = (ztpInfo: any[]) => {
    const dataSource = paginatedZtpInfo(ztpInfo).map((item) => ({
      key: item.callId,
      callId: item.callId,
      isFatal: item.ztp.rudeOrUnprofessional.isFatal,
      remarks: item.ztp.rudeOrUnprofessional.remarks,
    }));

    return (
      <>
        <Table dataSource={dataSource} columns={ztpTableColumns} pagination={false} />
        <Pagination
          current={currentZtpPage}
          pageSize={ztpItemsPerPage}
          total={ztpInfo.length}
          onChange={handleZtpPageChange}
          style={{ marginTop: 16, textAlign: 'center' }}
        />
      </>
    );
  };

  return (
    <div className="main-content">
      <div style={{ marginBottom: '24px' }}>
        <Title level={2}>Compliance Dashboard</Title>
        <Row align="middle" justify="space-between">
          <Col>
            <Typography.Text type="secondary" style={{ fontSize: '16px' }}>
              Showing compliance metrics for{' '}
              {showCurrentMonth
                ? new Date().toLocaleString('default', { month: 'long', year: 'numeric' })
                : new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleString(
                    'default',
                    {
                      month: 'long',
                      year: 'numeric',
                    }
                  )}
            </Typography.Text>
          </Col>
          <Col>
            <Row align="middle" gutter={8}>
              <Col>
                <Switch
                  checked={showCurrentMonth}
                  onChange={handleMonthToggle}
                  checkedChildren="Current Month"
                  unCheckedChildren="Last Month"
                />
              </Col>
              <Col>
                <Tooltip title="Metrics are calculated based on calls evaluated in the selected month">
                  <InfoCircleOutlined style={{ color: '#8c8c8c' }} />
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div className="card-container">
        <Card
          className="keyword-analysis-container card"
          title={
            <span>
              Keyword Analysis{' '}
              <Tooltip title="Analysis of compliance-related keywords detected in current month's calls">
                <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
              </Tooltip>
            </span>
          }
        >
          {isLoading ? (
            <Spin size="default" />
          ) : (
            <Tabs defaultActiveKey="1">
              <TabPane
                tab={
                  <span>
                    High Alert Keywords{' '}
                    <Tooltip title="Critical compliance violations detected this month">
                      <Tag color="red" style={{ marginLeft: 8 }}>
                        {escalations.highAlertKeywords.length}
                      </Tag>
                    </Tooltip>
                  </span>
                }
                key="1"
              >
                {renderKeywordSection(escalations.highAlertKeywords, true)}
              </TabPane>
              <TabPane
                tab={
                  <span>
                    Negative Keywords{' '}
                    <Tooltip title="Potential compliance issues identified this month">
                      <Tag color="orange" style={{ marginLeft: 8 }}>
                        {escalations.negativeKeywords.length}
                      </Tag>
                    </Tooltip>
                  </span>
                }
                key="2"
              >
                {renderKeywordSection(escalations.negativeKeywords, false)}
              </TabPane>
            </Tabs>
          )}
        </Card>
        <Card
          className="additional-data-container card"
          title={
            <span>
              Agent ZTP Information Overview{' '}
              <Tooltip title="Zero Tolerance Policy violations detected in current month">
                <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
              </Tooltip>
            </span>
          }
        >
          {isLoading ? <Spin size="default" /> : <>{renderZtpInfo(escalations.agentZtpInfo)}</>}
        </Card>
      </div>

      {/* <div className="card-container" style={{ marginTop: '24px' }}>
        <div
          className="additional-data-container"
          style={{
            // height: '20px',
            background: '#fff',
            border: '1px solid #f0f0f0',
            borderRadius: '8px',
            padding: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flex: 1,
            margin: '0 12px',
            fontSize: '16px',
            fontWeight: '600',
          }}
        >
          <span>RBI Guidelines</span>
          <Button
            onClick={() =>
              window.open(
                'https://www.rbi.org.in/commonman/English/scripts/Notification.aspx?Id=347',
                '_blank'
              )
            }
          >
            View Guidelines
          </Button>
        </div>
        <div
          className="additional-data-container"
          style={{
            // height: '20px',
            background: '#fff',
            border: '1px solid #f0f0f0',
            borderRadius: '8px',
            padding: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flex: 1,
            margin: '0 12px',
            fontSize: '16px',
            fontWeight: '600',
          }}
        >
          <span>Auto-allocate calls to agents</span>
          <Button>Allocate</Button>
        </div>
      </div> */}
    </div>
  );
};

export default ComplianceDashboard;
