import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../models/user.model';

export const initialUserState: { value: User } = {
  value: {
    _id: '',
    token: '',
    email: '',
    name: '',
    photoUrl: '',
    teamId: '',
    teamLogo: '',
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setUser(state: { value: User }, action: PayloadAction<User>) {
      state.value = action.payload;
    },
    setUserToken(state: { value: User }, action: PayloadAction<string>) {
      state.value.token = action.payload;
    },
    setUserTeamId(state: { value: User }, action: PayloadAction<string>) {
      state.value.teamId = action.payload;
    },
    clearUser(state: { value: User }) {
      state.value = initialUserState.value;
    },
  },
});

export const { setUser, setUserToken, setUserTeamId, clearUser } = userSlice.actions;

export default userSlice.reducer;
