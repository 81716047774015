import React, { useEffect, useState, useRef } from 'react';
import {
  Card,
  Row,
  Col,
  Statistic,
  Spin,
  Table,
  Tag,
  Typography,
  Tabs,
  Badge,
  Button,
  Tooltip,
  Switch,
} from 'antd';
import { Tooltip as RechartsTooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import { getCollectionDashboardMetrics } from '../../api/dashboard.api';
import { Link, useNavigate } from 'react-router-dom';
import type { TabsProps } from 'antd';
import Title from 'antd/es/typography/Title';
import { RoutePath } from '../../constants/routePaths';
import { InfoCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import {
  exportEasilyCollectibleMoneyReport,
  exportFieldCollectionCasesReport,
} from '../../api/export.api';
import { Chart, LinearScale, CategoryScale } from 'chart.js';
import { SankeyController, Flow } from 'chartjs-chart-sankey';

interface BusinessInsight {
  callId: string;
  tag: string;
  remarks: string;
  subTag?: string;
  evidence?: Array<{ start: number; end: number }>;
}

interface DashboardProps {
  data: {
    totalCalls: number;
    dispositions: Record<string, number>;
    totalCases: number;
    twelvePtpCount: number;
    ptpStock: number;
    businessInsights: BusinessInsight[];
    fieldCollectionCases: Array<{
      _id: string;
      customerId: string;
      loanAmount: number;
      cId: string;
    }>;
    lastMonthDispositions: Record<string, number>;
    easilyCollectibleMoney: Array<{
      _id: string;
      customerId: string;
      cId: string;
      loanAmount: number;
      businessInsightsTag: string;
      evidence: Array<{ start: number; end: number }>;
      callId: string;
    }>;
    paymentIntentTypes: Record<string, number>;
    lastMonthPaymentIntentTypes: Record<string, number>;
  };
}

const { Text } = Typography;

// Register the required components
Chart.register(SankeyController, Flow, LinearScale, CategoryScale);

interface SankeyDataPoint {
  from: string;
  to: string;
  flow: number;
}

const CollectionDashboard: React.FC<DashboardProps> = () => {
  const navigate = useNavigate();
  const [dispositionMetrics, setDispositionMetrics] = useState<DashboardProps['data'] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showCurrentMonth, setShowCurrentMonth] = useState(true);
  const sankeyChartRef = useRef<HTMLCanvasElement>(null);
  const sankeyChartInstance = useRef<Chart | null>(null);
  const [activeTab, setActiveTab] = useState<string>('');
  const [activeSubTab, setActiveSubTab] = useState<string>('');

  const fetchMetrics = async (isCurrentMonth: boolean) => {
    try {
      setIsLoading(true);
      const data = await getCollectionDashboardMetrics(isCurrentMonth);
      setDispositionMetrics(data);
    } catch (error) {
      console.error('Error fetching metrics:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMetrics(showCurrentMonth);
  }, [showCurrentMonth]);

  const handleMonthToggle = (checked: boolean) => {
    setShowCurrentMonth(checked);
  };

  // Only prepare the data if dispositionMetrics is not null and paymentIntentTypes exists
  const paymentIntentData = dispositionMetrics?.paymentIntentTypes
    ? Object.entries(dispositionMetrics.paymentIntentTypes).map(([name, value]) => ({
        name: name
          .split('_')
          .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
          .join(' '),
        value,
      }))
    : [];

  console.log(dispositionMetrics);

  // Modified function to prepare data in the format needed for chartjs-chart-sankey
  const prepareSankeyData = () => {
    if (!dispositionMetrics?.businessInsights?.length) {
      return [];
    }

    const insights = dispositionMetrics.businessInsights.filter(
      (insight) => insight.tag !== 'LANGUAGE_BARRIER_ISSUE'
    );
    const sankeyData = [];

    // Get counts by tag
    const tagCounts = insights.reduce(
      (acc, insight) => {
        acc[insight.tag] = (acc[insight.tag] || 0) + 1;
        return acc;
      },
      {} as Record<string, number>
    );

    // Get counts by subtag
    const subTagCounts = insights.reduce(
      (acc, insight) => {
        if (insight.subTag) {
          acc[insight.subTag] = (acc[insight.subTag] || 0) + 1;
        }
        return acc;
      },
      {} as Record<string, number>
    );

    // Add connections from "All Insights" to each main tag
    Object.entries(tagCounts).forEach(([tag, count]) => {
      sankeyData.push({
        from: `All Issues (${insights.length})`,
        to: `${formatLabel(tag)} (${count})`,
        flow: count,
      });
    });

    // Add single connection from tags to subtags
    Object.entries(subTagCounts).forEach(([subTag, count]) => {
      const parentTag = insights.find((insight) => insight.subTag === subTag)?.tag;
      if (parentTag) {
        sankeyData.push({
          from: `${formatLabel(parentTag)} (${tagCounts[parentTag]})`,
          to: `${formatLabel(subTag)} (${count})`,
          flow: count, // Single flow with the total count
        });
      }
    });

    return sankeyData;
  };

  // Helper function to format labels
  const formatLabel = (text: string) => {
    return text
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  // Function to create and update the Sankey chart
  useEffect(() => {
    if (!sankeyChartRef.current || !dispositionMetrics?.businessInsights?.length) {
      return;
    }

    // Generate professional colors for the nodes
    const generateColors = () => {
      // Use a professional blue color palette
      const baseColor = '#1890FF'; // Ant Design's primary blue
      return {
        'All Insights': baseColor,
        ...Object.fromEntries(
          Array.from(
            new Set(
              dispositionMetrics.businessInsights.flatMap((insight) =>
                [insight.tag, insight.subTag].filter(Boolean)
              )
            )
          ).map((tag, index) => [
            tag,
            `rgba(24, 144, 255, ${0.3 + index * 0.15})`, // Subtle variations of blue
          ])
        ),
      };
    };

    const colorMap = generateColors();

    // Cleanup existing chart if it exists
    if (sankeyChartInstance.current) {
      sankeyChartInstance.current.destroy();
    }

    // Create new chart
    const ctx = sankeyChartRef.current.getContext('2d');
    if (ctx) {
      sankeyChartInstance.current = new Chart(ctx, {
        type: 'sankey',
        data: {
          datasets: [
            {
              label: 'Business Insights Flow',
              data: prepareSankeyData(),
              colorFrom: (c) => {
                const from = c.dataset.data[c.dataIndex].from;
                const tagKey = Object.keys(colorMap).find(
                  (key) => formatLabel(key) === from || key === from
                );
                return tagKey ? colorMap[tagKey] : '#1890FF';
              },
              colorTo: (c) => {
                const to = c.dataset.data[c.dataIndex].to;
                const tagKey = Object.keys(colorMap).find(
                  (key) => formatLabel(key) === to || key === to
                );
                return tagKey ? colorMap[tagKey] : '#1890FF';
              },
              colorMode: 'gradient',
              /* Add labels to make the chart more readable */
              labels: Object.fromEntries(
                Array.from(
                  new Set(
                    dispositionMetrics.businessInsights.flatMap((insight) =>
                      [insight.tag, insight.subTag].filter(Boolean)
                    )
                  )
                ).map((tag) => [tag, formatLabel(tag as string)])
              ),
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          onClick: (event, elements) => {
            if (elements && elements.length > 0) {
              const element = elements[0];
              const dataPoint = sankeyChartInstance.current?.data.datasets[0].data[
                element.index
              ] as SankeyDataPoint;
              if (dataPoint) {
                // Extract the tag from the label (remove the count in parentheses)
                const tag = dataPoint.to.split(' (')[0];
                // Convert the formatted label back to the original tag format
                const originalTag = tag.toUpperCase().replace(/ /g, '_');

                // Find parent data point if this is a sub-wave
                const parentDataPoint = sankeyChartInstance.current?.data.datasets[0].data.find(
                  (d: SankeyDataPoint) => d.to === dataPoint.from
                ) as SankeyDataPoint;

                if (parentDataPoint) {
                  // This is a sub-wave
                  const parentTag = parentDataPoint.to
                    .split(' (')[0]
                    .toUpperCase()
                    .replace(/ /g, '_');
                  setActiveTab(parentTag);
                  setActiveSubTab(originalTag);
                } else {
                  // This is a main wave
                  setActiveTab(originalTag);
                  setActiveSubTab('');
                }

                // Scroll to the business insights section
                const businessInsightsCard = document.querySelector('.business-insights-card');
                if (businessInsightsCard) {
                  businessInsightsCard.scrollIntoView({ behavior: 'smooth' });
                }
              }
            }
          },
        },
      });
    }

    return () => {
      if (sankeyChartInstance.current) {
        sankeyChartInstance.current.destroy();
      }
    };
  }, [dispositionMetrics, showCurrentMonth]);

  // Colors for pie chart
  const COLORS = [
    '#52C41A', // High Confidence - Green
    '#1890FF', // Low Confidence - Blue
    '#FF4D4F', // Risky - Red
    '#BBBBBB', // Highly Risky - Gray
  ];

  // Add null check for businessInsights
  const groupedInsights = dispositionMetrics?.businessInsights
    ? dispositionMetrics.businessInsights.reduce(
        (acc, insight) => {
          if (!acc[insight.tag]) {
            acc[insight.tag] = {
              withoutSubtag: [],
              subtags: {},
            };
          }

          if (insight.subTag) {
            if (!acc[insight.tag].subtags[insight.subTag]) {
              acc[insight.tag].subtags[insight.subTag] = [];
            }
            acc[insight.tag].subtags[insight.subTag].push(insight);
          } else {
            acc[insight.tag].withoutSubtag.push(insight);
          }
          return acc;
        },
        {} as Record<
          string,
          {
            withoutSubtag: BusinessInsight[];
            subtags: Record<string, BusinessInsight[]>;
          }
        >
      )
    : {};

  const insightColumns = [
    {
      title: 'Insight',
      dataIndex: 'remarks',
      key: 'remarks',
      width: '75%',
    },
    {
      title: 'Action',
      key: 'action',
      width: '25%',
      render: (_, record: BusinessInsight) => {
        // Get the middle evidence if multiple exist
        const evidenceIndex = Math.floor((record.evidence.length - 1) / 2);
        const evidence = record.evidence[evidenceIndex];

        return (
          <Button
            type="link"
            icon={<PlayCircleOutlined />}
            onClick={() => {
              const params = new URLSearchParams();
              params.append('callId', record.callId);

              // Only add evidence parameters if evidence array exists and has items
              if (record.evidence && record.evidence.length > 0) {
                // Get the middle evidence if multiple exist
                const evidenceIndex = Math.floor((record.evidence.length - 1) / 2);
                const evidence = record.evidence[evidenceIndex];

                params.append('playFrom', (evidence.start - 3).toString());
                params.append('playTo', (evidence.end + 3).toString());
              }

              navigate(`${RoutePath.CALL_ANALYSIS}?${params}`);
            }}
          >
            Play Evidence
          </Button>
        );
      },
    },
  ];

  const getTagColor = (count: number) => {
    if (count >= 50) return '#f50'; // Red for high counts
    if (count >= 25) return '#fa8c16'; // Orange for medium counts
    return '#52c41a'; // Green for low counts
  };

  // Modified tab items creation with null check and language barrier filter
  const tabItems: TabsProps['items'] = Object.entries(groupedInsights || {})
    .filter(([tag]) => tag !== 'LANGUAGE_BARRIER_ISSUE')
    .sort(([, a], [, b]) => {
      const totalA = a.withoutSubtag.length + Object.values(a.subtags).flat().length;
      const totalB = b.withoutSubtag.length + Object.values(b.subtags).flat().length;
      return totalB - totalA;
    })
    .map(([tag, { withoutSubtag, subtags }]) => {
      const totalCount = withoutSubtag.length + Object.values(subtags).flat().length;

      // If there are subtags, create nested tabs
      const hasSubtags = Object.keys(subtags).length > 0;
      const content = hasSubtags ? (
        <Tabs
          activeKey={activeSubTab}
          onChange={(key) => setActiveSubTab(key)}
          items={[
            ...Object.entries(subtags).map(([subTag, insights]) => ({
              key: subTag,
              label: (
                <span>
                  {subTag
                    .split('_')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(' ')}{' '}
                  <Tag color={getTagColor(insights.length)}>{insights.length}</Tag>
                </span>
              ),
              children: (
                <Table
                  dataSource={insights}
                  columns={insightColumns}
                  pagination={{ pageSize: 5, showSizeChanger: false }}
                  rowKey="callId"
                />
              ),
            })),
            ...(withoutSubtag.length > 0
              ? [
                  {
                    key: 'others',
                    label: (
                      <span>
                        Others{' '}
                        <Tag color={getTagColor(withoutSubtag.length)}>{withoutSubtag.length}</Tag>
                      </span>
                    ),
                    children: (
                      <Table
                        dataSource={withoutSubtag}
                        columns={insightColumns}
                        pagination={{ pageSize: 5, showSizeChanger: false }}
                        rowKey="callId"
                      />
                    ),
                  },
                ]
              : []),
          ]}
        />
      ) : (
        <Table
          dataSource={withoutSubtag}
          columns={insightColumns}
          pagination={{ pageSize: 5, showSizeChanger: false }}
          rowKey="callId"
        />
      );

      return {
        key: tag,
        label: (
          <span>
            {tag
              .split('_')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
              .join(' ')}{' '}
            <Tag color={getTagColor(totalCount)}>{totalCount}</Tag>
          </span>
        ),
        children: content,
      };
    });

  const handlePieClick = (data: any) => {
    const formattedDisposition = data.name.toUpperCase().split(' ').join('_');
    navigate(`${RoutePath.ALL_CALLS}?disposition=${formattedDisposition}`);
  };

  // Custom tooltip component
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload[0]) {
      const currentValue = payload[0].value;
      const dispositionName = payload[0].name;
      // Convert the display name back to the API format for lookup
      const apiFormatName = dispositionName.toUpperCase().replace(/ /g, '_');
      const lastMonthValue = dispositionMetrics?.lastMonthPaymentIntentTypes[apiFormatName] || 0;

      const percentageChange =
        lastMonthValue === 0
          ? 100 // If last month was 0, show 100% increase
          : ((currentValue - lastMonthValue) / lastMonthValue) * 100;

      return (
        <div
          style={{
            backgroundColor: 'white',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
        >
          <p>
            <strong>{dispositionName}</strong>
          </p>
          <p>Current: {currentValue}</p>
          <p>Last Month: {lastMonthValue}</p>
          <p
            style={{
              color: percentageChange >= 0 ? '#52c41a' : '#f5222d',
            }}
          >
            {percentageChange >= 0 ? '↑' : '↓'} {Math.abs(percentageChange).toFixed(1)}%
          </p>
        </div>
      );
    }
    return null;
  };

  const handleexportEasilyCollectibleMoneyReport = async () => {
    try {
      setIsDownloading(true);
      const { url } = await exportEasilyCollectibleMoneyReport();
      const link = document.createElement('a');
      link.href = url;
      link.download = '';
      link.click();
    } catch (error) {
      console.error('Error exporting report:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleExportFieldCollectionCasesReport = async () => {
    try {
      setIsDownloading(true);
      const { url } = await exportFieldCollectionCasesReport();
      const link = document.createElement('a');
      link.href = url;
      link.download = '';
      link.click();
    } catch (error) {
      console.error('Error exporting field collection cases report:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  if (isLoading || !dispositionMetrics) {
    return (
      <div style={{ padding: '24px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ padding: '24px' }}>
      <div style={{ marginBottom: '24px' }}>
        <Title level={2}>Collection Analytics Dashboard</Title>
        <Row align="middle" justify="space-between">
          <Col>
            <Typography.Text type="secondary" style={{ fontSize: '16px' }}>
              Showing collection metrics for{' '}
              {showCurrentMonth
                ? new Date().toLocaleString('default', { month: 'long', year: 'numeric' })
                : new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleString(
                    'default',
                    {
                      month: 'long',
                      year: 'numeric',
                    }
                  )}
            </Typography.Text>
          </Col>
          <Col>
            <Row align="middle" gutter={8}>
              <Col>
                <Switch
                  checked={showCurrentMonth}
                  onChange={handleMonthToggle}
                  checkedChildren="Current Month"
                  unCheckedChildren="Last Month"
                />
              </Col>
              <Col>
                <Tooltip title="Metrics are calculated based on collections data from the selected month">
                  <InfoCircleOutlined style={{ color: '#8c8c8c' }} />
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {isLoading ? (
        <Spin size="default" />
      ) : (
        <>
          {/* Main Row with Stats and Pie Chart */}
          <Row gutter={[24, 24]}>
            {/* Left Column - Statistics */}
            <Col span={6}>
              <Card className="metrics-card">
                <div className="stats-container">
                  <div className="stat-item">
                    <Link to={`${RoutePath.ALL_CALLS}`}>
                      <Statistic
                        title={
                          <span>
                            Total Calls This Month{' '}
                            <Tooltip title="Number of collection calls made in current month">
                              <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
                            </Tooltip>
                          </span>
                        }
                        value={dispositionMetrics.totalCalls}
                      />
                    </Link>
                  </div>
                  <div className="stat-item">
                    <Link to={`${RoutePath.ALL_CUSTOMERS}`}>
                      <Statistic
                        title={
                          <span>
                            Active Cases{' '}
                            <Tooltip title="Number of active collection cases">
                              <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
                            </Tooltip>
                          </span>
                        }
                        value={dispositionMetrics.totalCases}
                      />
                    </Link>
                  </div>
                  <div className="stat-item">
                    <Statistic
                      title={
                        <span>
                          Current PTP Stock{' '}
                          <Tooltip title="Total value of Promise to Pay commitments for current month">
                            <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
                          </Tooltip>
                        </span>
                      }
                      value={dispositionMetrics.ptpStock}
                      prefix="₹"
                      precision={0}
                    />
                  </div>
                </div>
              </Card>
            </Col>

            {/* Right Column - Pie Chart */}
            <Col span={18}>
              <Card
                title={
                  <span>
                    Payment Intent Distribution{' '}
                    <Tooltip title="Distribution of payment intent types for the current month">
                      <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
                    </Tooltip>
                  </span>
                }
                style={{ height: '500px' }}
              >
                <div className="chart-container">
                  <PieChart width={700} height={400}>
                    <Pie
                      data={paymentIntentData}
                      cx={250}
                      cy={200}
                      innerRadius={90}
                      outerRadius={150}
                      paddingAngle={2}
                      dataKey="value"
                      style={{ cursor: 'pointer' }}
                      onClick={(data) => {
                        const formattedTag = data.name.toUpperCase().replace(/ /g, '_');
                        navigate(`${RoutePath.ALL_CUSTOMERS}?paymentIntent=${formattedTag}`);
                      }}
                      label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                        const RADIAN = Math.PI / 180;
                        const radius = outerRadius + 35;
                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                        const y = cy + radius * Math.sin(-midAngle * RADIAN);

                        return (
                          <text
                            x={x}
                            y={y}
                            fill={COLORS[index]}
                            textAnchor={x > cx ? 'start' : 'end'}
                            dominantBaseline="central"
                          >
                            {value}
                          </text>
                        );
                      }}
                      labelLine={({ cx, cy, midAngle, outerRadius, index }) => {
                        const RADIAN = Math.PI / 180;
                        const radius = outerRadius + 30;
                        const x1 = cx + outerRadius * Math.cos(-midAngle * RADIAN);
                        const y1 = cy + outerRadius * Math.sin(-midAngle * RADIAN);
                        const x2 = cx + radius * Math.cos(-midAngle * RADIAN);
                        const y2 = cy + radius * Math.sin(-midAngle * RADIAN);

                        return (
                          <path d={`M${x1},${y1}L${x2},${y2}`} stroke={COLORS[index]} fill="none" />
                        );
                      }}
                    >
                      {paymentIntentData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index]} />
                      ))}
                    </Pie>
                    <Legend
                      layout="vertical"
                      align="right"
                      verticalAlign="middle"
                      iconType="circle"
                      formatter={(value) => value}
                      wrapperStyle={{
                        paddingLeft: '40px',
                      }}
                    />
                    <RechartsTooltip content={<CustomTooltip />} />
                  </PieChart>
                </div>
              </Card>
            </Col>
          </Row>

          {/* Tables Row */}
          <Row gutter={[24, 24]} style={{ marginTop: '24px' }}>
            {/* Easily Collectible Money */}
            <Col span={12}>
              <Card
                title={
                  <span>
                    Easily Collectible Money{' '}
                    <Tooltip title="Cases identified as high probability for collection this month">
                      <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
                    </Tooltip>
                  </span>
                }
                style={{ height: '500px' }}
                extra={
                  <Button
                    type="default"
                    onClick={handleexportEasilyCollectibleMoneyReport}
                    loading={isDownloading}
                  >
                    Download Report
                  </Button>
                }
              >
                <Table
                  dataSource={dispositionMetrics.easilyCollectibleMoney.sort(
                    (a, b) => b.loanAmount - a.loanAmount
                  )}
                  pagination={{
                    pageSize: 5,
                    showSizeChanger: false,
                    showTotal: (total) => `Total ${total} customers`,
                  }}
                  onRow={(record) => ({
                    onClick: () => {
                      // Get the middle evidence if multiple exist
                      const params = new URLSearchParams();
                      params.append('callId', record.callId);

                      // Only add evidence parameters if evidence array exists and has items
                      if (record.evidence && record.evidence.length > 0) {
                        // Get the middle evidence if multiple exist
                        const evidenceIndex = Math.floor((record.evidence.length - 1) / 2);
                        const evidence = record.evidence[evidenceIndex];

                        params.append('playFrom', (evidence.start - 3).toString());
                        params.append('playTo', (evidence.end + 3).toString());
                      }

                      navigate(`${RoutePath.CALL_ANALYSIS}?${params}`);
                    },
                    style: { cursor: 'pointer' },
                  })}
                  columns={[
                    {
                      title: 'Customer ID',
                      dataIndex: 'cId',
                      key: 'cId',
                      render: (text: string, record) => (
                        // <Link
                        //   to={`${RoutePath.CUSTOMER_DETAILS}?customerId=${record.customerId}`}
                        //   style={{ color: 'black' }}
                        // >
                        <>{text}</>
                        // </Link>
                      ),
                    },
                    {
                      title: 'Loan Amount',
                      dataIndex: 'loanAmount',
                      key: 'loanAmount',
                      render: (amount: number) => (
                        <Text strong style={{ color: '#52C41A' }}>
                          ₹{(amount ?? 0).toLocaleString()}
                        </Text>
                      ),
                    },
                    {
                      title: 'Tag',
                      dataIndex: 'businessInsightsTag',
                      key: 'businessInsightsTag',
                      render: (tag: string) => (
                        <Tag color="blue">
                          {tag
                            .split('_')
                            .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
                            .join(' ')}
                        </Tag>
                      ),
                    },
                  ]}
                />
              </Card>
            </Col>

            {/* Field Collections */}
            <Col span={12}>
              <Card
                title={
                  <span>
                    Field Collection Cases{' '}
                    <Tooltip title="Cases recommended for field collection based on current month's interactions">
                      <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
                    </Tooltip>
                  </span>
                }
                style={{ height: '500px' }}
                extra={
                  <Button
                    type="default"
                    onClick={handleExportFieldCollectionCasesReport}
                    loading={isDownloading}
                  >
                    Download Report
                  </Button>
                }
              >
                <Table
                  dataSource={dispositionMetrics.fieldCollectionCases}
                  pagination={{
                    pageSize: 5,
                    showSizeChanger: false,
                    showTotal: (total) => `Total ${total} cases`,
                  }}
                  columns={[
                    {
                      title: 'Customer ID',
                      dataIndex: 'cId',
                      key: 'cId',
                      render: (text: string, record) => (
                        <Link
                          to={`${RoutePath.CUSTOMER_DETAILS}?customerId=${record.customerId}`}
                          style={{ color: 'black' }}
                        >
                          {text}
                        </Link>
                      ),
                    },
                    {
                      title: 'Loan Amount',
                      dataIndex: 'loanAmount',
                      key: 'loanAmount',
                      render: (amount: number) => <Text>₹{(amount ?? 0).toLocaleString()}</Text>,
                    },
                    {
                      title: 'Status',
                      dataIndex: '',
                      key: '',
                      render: () => <Tag color="red">Risky Customer</Tag>,
                    },
                  ]}
                />
              </Card>
            </Col>
          </Row>

          {/* Business Insights */}
          <Row style={{ marginTop: '24px' }}>
            <Col span={24}>
              <Card
                className="business-insights-card"
                title={
                  <span>
                    Business Insights{' '}
                    <Tooltip title="Key insights derived from current month's collection activities">
                      <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
                    </Tooltip>
                  </span>
                }
              >
                <Tabs
                  items={tabItems}
                  activeKey={activeTab || Object.keys(groupedInsights)[1]}
                  onChange={(key) => {
                    setActiveTab(key);
                    setActiveSubTab('');
                  }}
                  defaultActiveKey={activeSubTab}
                />
                <br />
                <br />
                <br />
                <div
                  style={{
                    height: '200px',
                    width: '600px',
                    marginBottom: '24px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <canvas ref={sankeyChartRef} />
                </div>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default CollectionDashboard;
