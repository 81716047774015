import { callApi } from '.';

export const getAllAgents = async () => {
  return await callApi({
    method: 'GET',
    url: '/agent/all',
    serializerFunc: (response: any) => response.data,
  });
};

export const getAgentDetails = async ({ agentId }: { agentId: string }) => {
  const params = new URLSearchParams({ agentId });
  return await callApi({
    method: 'GET',
    url: `/agent?${params.toString()}`,
    serializerFunc: (response: any) => response.data,
  });
};

export const updateTeamTni = async () => {
  return await callApi({
    method: 'PUT',
    url: `/agent/update-tni`,
    serializerFunc: (response: any) => response.data,
  });
};
