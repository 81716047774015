import React, { useState } from 'react';
import { InboxOutlined, FileExcelOutlined } from '@ant-design/icons';
import {
  message,
  Upload,
  UploadProps,
  Button,
  Alert,
  List,
  Card,
  Typography,
  Collapse,
} from 'antd';
import { v4 as uuidv4 } from 'uuid';
import AWS from 'aws-sdk';
import './UploadCalls.css';
import { processCall, processExcel, validateExcelUpload } from '../../api/call.api';
import toast from 'react-hot-toast';
import Title from 'antd/es/typography/Title';

const audioMimeTypes = [
  'audio/mpeg', // MP3
  'audio/wav', // WAV
  'audio/ogg', // OGG
  'audio/flac', // FLAC
  'audio/aac', // AAC
  'audio/x-wav', // WAV
  'audio/x-ms-wma', // WMA
  'audio/x-aiff', // AIFF
  'audio/webm', // WEBM
];

const excelMimeTypes = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const { Dragger } = Upload;

const config = {
  bucketName: process.env.REACT_APP_AWS_BUCKET_NAME,
  region: process.env.REACT_APP_AWS_REGION_NAME,
  accessKeyId: process.env.REACT_APP_AWS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
};

const callsDirName = process.env.REACT_APP_AWS_S3_CALLS_DIR_NAME;
const excelDirName = process.env.REACT_APP_AWS_S3_EXCEL_DIR_NAME;

interface ValidationResponse {
  isValid: boolean;
  errors: string[];
  rowCount: number;
  detectedHeaders: string[];
}

const { Text, Paragraph } = Typography;

const UploadCalls: React.FC = () => {
  const [uploadedCallFiles, setUploadedCallFiles] = useState<any[]>([]);
  const [uploadedExcelFile, setUploadedExcelFile] = useState<{ key: string; name: string }>();
  const [validationResponse, setValidationResponse] = useState<ValidationResponse | null>(null);

  const handleFileUpload = async (file: any, config: any, dirName: string, fileName: string) => {
    const s3 = new AWS.S3({
      accessKeyId: config.accessKeyId,
      secretAccessKey: config.secretAccessKey,
      region: config.region,
      httpOptions: {
        timeout: 300000,
      },
    });

    const params = {
      Bucket: config.bucketName,
      Key: `${dirName}/${fileName}`,
      Body: file,
    };

    return new Promise((resolve, reject) => {
      s3.upload(params).send(async (err: any, data: any) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  };

  const excelUploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    beforeUpload: async (file: any) => {
      try {
        const toastId = toast.loading(`Uploading file '${file.name}'...`);
        if (!excelMimeTypes.includes(file.type)) {
          toast.error('Upload failed: Only excel files can be uploaded', {
            id: toastId,
          });
          return false;
        }
        const fileName = `${file.name.split('.')[0]}@${uuidv4()}.${file.name.split('.')[1]}`;
        const data: any = await handleFileUpload(file, config as any, excelDirName, fileName);
        const validationResponse = await validateExcelUpload({ excelKey: data.Key });
        setValidationResponse(validationResponse);
        if (!validationResponse.isValid) {
          toast.error(`Upload failed: Validation failed.`, { id: toastId });
          return false;
        }
        setUploadedExcelFile({
          key: data.Key,
          name: file.name,
        });
        toast.success(`File '${file.name}' uploaded successfully.`, { id: toastId });
      } catch (error: any) {
        message.error(`Upload failed: ${error.message}.`);
      }
      return false;
    },
  };

  const handleProcessCalls = async () => {
    try {
      message.info('Adding calls to queue for processing...');
      await processCall({ keys: uploadedCallFiles.map((file) => file.key), language: 'hi' });
      message.success('Calls added to queue for processing successfully.');
    } catch (error: any) {
      message.error(`Failed to add calls to queue for processing: ${error.message}.`);
    } finally {
      setUploadedCallFiles([]);
    }
  };
  const handleProcessExcel = async () => {
    const toastId = toast.loading(`Parsing Excel File...`);
    try {
      await processExcel({ excelKey: uploadedExcelFile.key, language: 'hi' });
      toast.success('Excel file parsed successfully and calls added to queue for processing.', {
        id: toastId,
      });
    } catch (error: any) {
      toast.error(`Failed to parse excel file: ${error.message}.`, { id: toastId });
    } finally {
      setUploadedExcelFile({ key: '', name: '' });
      setValidationResponse(null);
    }
  };

  return (
    <div className="main-content">
      <Title level={2}>Upload Calls</Title>

      {/* Card 1: Upload Area */}
      <Card style={{ marginTop: 24 }}>
        <Dragger {...excelUploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined style={{ fontSize: 48, color: '#40a9ff' }} />
          </p>
          <p className="ant-upload-text" style={{ fontSize: 16, fontWeight: 500 }}>
            Click or drag an excel file with URLs to this area to upload
          </p>
          <p className="ant-upload-hint" style={{ color: '#666' }}>
            Supported formats: .xlsx, .xls
          </p>
        </Dragger>
      </Card>

      {/* Card 2: Validation and File Info */}
      {(validationResponse || uploadedExcelFile?.key) && (
        <Card style={{ marginTop: 24 }}>
          <div style={{ display: 'flex', gap: '24px' }}>
            {/* Left section: Validation Results */}
            <div style={{ flex: 1 }}>
              {validationResponse && (
                <>
                  <Alert
                    message={validationResponse.isValid ? 'Validation Passed' : 'Validation Failed'}
                    type={validationResponse.isValid ? 'success' : 'error'}
                    showIcon
                    description={
                      <Text type="secondary">{validationResponse.rowCount} rows detected</Text>
                    }
                    style={{ marginBottom: 16 }}
                  />

                  {/* Only show headers section if there are headers */}
                  {validationResponse.detectedHeaders?.length > 0 && (
                    <Collapse>
                      <Collapse.Panel header={<Text strong>Detected Headers</Text>} key="headers">
                        <List
                          size="small"
                          dataSource={validationResponse.detectedHeaders}
                          renderItem={(header) => (
                            <List.Item>
                              <Text>{header}</Text>
                            </List.Item>
                          )}
                        />
                      </Collapse.Panel>
                    </Collapse>
                  )}

                  {validationResponse?.errors.length > 0 && (
                    <Collapse style={{ marginTop: 16 }}>
                      <Collapse.Panel
                        header={
                          <Text
                            strong
                          >{`Validation Errors (${validationResponse.errors.length})`}</Text>
                        }
                        key="errors"
                      >
                        <List
                          size="small"
                          dataSource={validationResponse.errors}
                          renderItem={(error) => (
                            <List.Item>
                              <Text type="danger">{error}</Text>
                            </List.Item>
                          )}
                        />
                      </Collapse.Panel>
                    </Collapse>
                  )}
                </>
              )}
            </div>

            {/* Right section: File Info and Actions */}
            {uploadedExcelFile && uploadedExcelFile.key && (
              <div
                style={{
                  flex: 1,
                  borderLeft: '1px solid #f0f0f0',
                  paddingLeft: '24px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <Title level={4} style={{ margin: 0 }}>
                    Uploaded Excel File
                  </Title>
                  <Text type="secondary">{uploadedExcelFile.name}</Text>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button type="primary" onClick={handleProcessExcel} icon={<FileExcelOutlined />}>
                    Process Excel File
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Card>
      )}
    </div>
  );
};

export default UploadCalls;
