import React, { useEffect, useState } from 'react';
import { Table, Tag, Spin, DatePicker, Button, Card, Typography, Row, Col, Statistic } from 'antd';
import { ColumnsType } from 'antd/es/table';
import './CustomerJourney.css';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../constants/routePaths';
import { getCustomerCalls } from '../../api/customer.api';

const { RangePicker } = DatePicker;
const { Title, Text } = Typography;

interface CallData {
  id: string;
  key: string;
  inputDate: string;
  duration: number;
  score: string;
  status: 'completed' | 'processing' | 'waiting' | 'failed';
  disposition: string;
}

interface CustomerData {
  _id: string;
  cId: string;
  loanAmount: string;
  paymentIntentType?: string;
  paymentIntentTypeRemarks?: string;
  journeySummary?: string;
}

const CustomerJourney: React.FC = () => {
  const customerId = new URL(window.location.href).searchParams.get('customerId');
  if (!customerId) {
    window.location.href = RoutePath.HOME;
  }

  const [callData, setCallData] = useState<CallData[]>([]);
  const [customerData, setCustomerData] = useState<CustomerData>();
  const [isLoading, setIsLoading] = useState(true);
  const [pickerValue, setPickerValue] = useState<[string, string] | null>(null);

  useEffect(() => {
    getCustomerCalls({ customerId }).then((data) => {
      const calls = data.calls.map((call: any) => ({
        id: call._id,
        key: call.key,
        inputDate: call.inputDate,
        timestamp: new Date(call.inputDate).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }),
        duration: call.duration,
        score:
          call.callScore && call.totalScore && call.totalScore !== 0
            ? `${Math.round((call.callScore / call.totalScore) * 10000) / 100}%`
            : '-',
        status: call.processStatus,
        disposition: call.disposition,
      }));
      setCallData(
        calls.sort((a, b) => new Date(b.inputDate).getTime() - new Date(a.inputDate).getTime())
      );
      setCustomerData(data.customer);
      setIsLoading(false);
    });
  }, []);

  const columns: ColumnsType<CallData> = [
    {
      title: 'Call',
      dataIndex: 'key',
      key: 'key',
      render: (key: string) => <span>{key.split('/')[1]}</span>,
      width: '30%',
    },
    // {
    //   title: 'Score',
    //   dataIndex: 'score',
    //   key: 'score',
    //   width: '10%',
    // },
    {
      title: 'Disposition',
      dataIndex: 'disposition',
      key: 'disposition',
      width: '10%',
      render: (disposition: string) => {
        const colorMap = {
          PTP: 'green',
          RTP: 'red',
          CALLBACK: 'blue',
          ALREADY_PAID: 'purple',
          DISPUTE: 'orange',
          NRPC: 'volcano',
          NEUTRAL: 'default',
        };

        const displayText: Record<string, string> = {
          PTP: 'PTP',
          RTP: 'RTP',
          CALLBACK: 'Callback',
          ALREADY_PAID: 'Already Paid',
          DISPUTE: 'Dispute',
          NRPC: 'NRPC',
          NEUTRAL: 'Neutral',
        };

        return (
          <Tag color={colorMap[disposition as keyof typeof colorMap]}>
            {displayText[disposition] || disposition}
          </Tag>
        );
      },
    },
    {
      title: 'Call Date & Time',
      dataIndex: 'timestamp',
      key: 'timestamp',
      width: '20%',
      sorter: (a, b) => new Date(a.inputDate).getTime() - new Date(b.inputDate).getTime(),
    },
    {
      title: 'Processing Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (status: string) => {
        const statusColors: Record<string, string> = {
          completed: 'green',
          processing: 'gold',
          waiting: 'blue',
          failed: 'red',
        };
        return <Tag color={statusColors[status]}>{status}</Tag>;
      },
      filters: [
        { text: 'Completed', value: 'Completed' },
        { text: 'Processing', value: 'Processing' },
        { text: 'Waiting', value: 'Waiting' },
        { text: 'Failed', value: 'Failed' },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      dataIndex: 'id',
      key: 'id',
      width: '10%',
      render: (id: string, record: CallData) => {
        if (record.status === 'completed') {
          return <Link to={`${RoutePath.CALL_ANALYSIS}?callId=${id}`}>View Call</Link>;
        } else {
          return <span></span>;
        }
      },
    },
  ];

  return (
    <div className="main-content">
      <Title level={2}>Customer Journey</Title>
      {isLoading ? (
        <Spin size="default" />
      ) : (
        <>
          <Row gutter={[24, 24]}>
            <Col span={8}>
              <Card>
                <Statistic
                  title="Customer ID"
                  value={customerData?.cId}
                  formatter={(value) => String(value)}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic title="Loan Amount" value={customerData?.loanAmount} prefix="₹" />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic
                  title="Total Calls"
                  value={callData.length}
                  // valueStyle={{ color: '#722ed1' }}
                />
              </Card>
            </Col>
          </Row>

          <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
            {customerData?.paymentIntentType && (
              <Col span={24}>
                <Card
                  title={
                    <Text strong style={{ fontSize: 16 }}>
                      Customer Risk Profile
                    </Text>
                  }
                >
                  <Text
                    style={{
                      display: 'block',
                      marginBottom: 16,
                      fontSize: 15,
                      fontWeight: 500,
                      color:
                        customerData.paymentIntentType === 'RISKY'
                          ? '#cf1322'
                          : customerData.paymentIntentType === 'LOW_CONFIDENCE'
                            ? '#1890ff'
                            : customerData.paymentIntentType === 'HIGH_CONFIDENCE'
                              ? '#389e0d'
                              : 'inherit',
                    }}
                  >
                    {customerData.paymentIntentType
                      .split('_')
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                      .join(' ')}{' '}
                    Customer
                  </Text>
                  {customerData.paymentIntentTypeRemarks && (
                    <Text type="secondary" style={{ display: 'block' }}>
                      {customerData.paymentIntentTypeRemarks}
                    </Text>
                  )}
                </Card>
              </Col>
            )}

            {/* {customerData?.journeySummary && (
              <Col span={24}>
                <Card>
                  <Title level={5}>Journey Summary</Title>
                  <Text style={{ whiteSpace: 'pre-line' }}>{customerData.journeySummary}</Text>
                </Card>
              </Col>
            )} */}
          </Row>

          <Card
            style={{ marginTop: 24 }}
            title={
              <Text strong style={{ fontSize: 16 }}>
                All Customer Calls
              </Text>
            }
          >
            <Table
              columns={columns}
              dataSource={callData}
              rowKey="id"
              pagination={{
                pageSize: 10,
              }}
            />
          </Card>
        </>
      )}
    </div>
  );
};

export default CustomerJourney;
