import React, { useEffect, useState } from 'react';
import { Table, Card, Row, Col, Statistic, Tag, Typography, Input, Spin, Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../constants/routePaths';
import { getAllCustomers } from '../../api/customer.api';
import {
  exportCustomerDispositionReport,
  exportEasilyCollectibleMoneyReport,
} from '../../api/export.api';

const { Title, Text } = Typography;
const { Search } = Input;

interface CustomerData {
  _id: string;
  cId: string;
  loanAmount: string;
  totalCalls: number;
  paymentIntentType: 'HIGH_CONFIDENCE' | 'LOW_CONFIDENCE' | 'RISKY' | 'NEUTRAL';
}

const AllCustomers = () => {
  const [searchText, setSearchText] = useState('');
  const [customerData, setCustomerData] = useState<CustomerData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentIntentFilter, setPaymentIntentFilter] = useState<string | null>(null);

  useEffect(() => {
    // Get paymentIntent from URL parameters
    const params = new URLSearchParams(window.location.search);
    const paymentIntent = params.get('paymentIntent');
    setPaymentIntentFilter(paymentIntent);

    getAllCustomers().then((data) => {
      setCustomerData(data.customers);
      setIsLoading(false);
    });
  }, []);

  // const handleExportCustomerDispositionReport = async () => {
  //   try {
  //     setIsDownloading(true);
  //     const { url } = await exportCustomerDispositionReport();
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.download = '';
  //     link.click();
  //   } catch (error) {
  //     console.error('Error exporting report:', error);
  //   } finally {
  //     setIsDownloading(false);
  //   }
  // };

  const columns: ColumnsType<CustomerData> = [
    {
      title: 'Customer ID',
      dataIndex: 'cId',
      key: 'cId',
      render: (text: string, record) => (
        <Link to={`${RoutePath.CUSTOMER_DETAILS}?customerId=${record._id}`}>{text}</Link>
      ),
      filteredValue: [searchText],
      onFilter: (value, record) =>
        record.cId.toLowerCase().includes(value.toString().toLowerCase()),
    },
    {
      title: 'Loan Amount',
      dataIndex: 'loanAmount',
      key: 'loanAmount',
      render: (amount: string) => <Text>₹{parseInt(amount).toLocaleString()}</Text>,
      sorter: (a, b) => parseInt(a.loanAmount) - parseInt(b.loanAmount),
    },
    {
      title: 'Total Calls',
      dataIndex: 'totalCalls',
      key: 'totalCalls',
      sorter: (a, b) => a.totalCalls - b.totalCalls,
    },
    {
      title: 'Payment Intent',
      dataIndex: 'paymentIntentType',
      key: 'paymentIntentType',
      render: (type: string) => {
        const colorMap = {
          HIGH_CONFIDENCE: 'green',
          LOW_CONFIDENCE: 'blue',
          RISKY: 'red',
          NEUTRAL: '',
        };

        const formattedText = type
          .split('_')
          .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
          .join(' ');

        return <Tag color={colorMap[type]}>{formattedText}</Tag>;
      },
      filteredValue: paymentIntentFilter ? [paymentIntentFilter] : null,
      onFilter: (value, record) => record.paymentIntentType === value,
      sorter: (a, b) => a.paymentIntentType.localeCompare(b.paymentIntentType),
    },
  ];

  return (
    <div style={{ padding: '24px' }}>
      <Title level={2}>All Customers</Title>

      {isLoading ? (
        <Spin size="large" />
      ) : (
        <Card>
          <Row gutter={16}>
            <Col>
              <Search
                placeholder="Search by Customer ID"
                allowClear
                onChange={(e) => setSearchText(e.target.value)}
                style={{ marginBottom: '16px', maxWidth: '300px' }}
              />
            </Col>
            <Col>
              {/* <Button
                type="default"
                onClick={handleExportCustomerDispositionReport}
                loading={isDownloading}
              >
                Download Report
              </Button> */}
            </Col>
          </Row>

          <Table
            columns={columns}
            dataSource={customerData}
            rowKey="_id"
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} customers`,
            }}
          />
        </Card>
      )}
    </div>
  );
};

export default AllCustomers;
