interface TeamConfig {
  COMPLETE_TEAMS: string[];
  QUALITY_TEAMS: string[];
}

const devConfig: TeamConfig = {
  COMPLETE_TEAMS: [
    '674823b41aaa5d2654b07046',
    '677fd998a1078b28e6698a34',
    '6790d9c62c60969416c37f66',
    '67860ff6eb7ca40f25148bec',
    '6799e057dd26302ac45762cc',
    '67a47d3ef2a1e9be9a8ba6d5',
    '67a4bf34621ac0baa8bbe19c', // this is a local team
    '677e6429529c156bad1753cd',
    '67c29dca7d4ac19f74c53632',
    '67c861c6869bb494892a7686',
  ],
  QUALITY_TEAMS: ['677e6429529c156bad1753cd'],
};

const prodConfig: TeamConfig = {
  COMPLETE_TEAMS: [
    '677e7d8bfdf4de3c4e4936d4',
    '678611e247bea106224a9173',
    '67875cb1fa4ab5eef1e8b07d',
    '678a36287b54d7779ef0c60d',
    '679b3d7c9aeb707844df3cbe',
    '67a1b8dafa6b820ffc043f12',
    '67a1f4e80ba095ebe2da063d',
    '67a3427d675af77765b474a5',
    '67af1f5ab8793ebab657c18a', // Branch team
    '67c2db539e3e675680bafbfb', // GrayQuest team
  ],
  QUALITY_TEAMS: ['679357f452651c449993ae32'],
};

export const getTeamConfig = (): TeamConfig => {
  return process.env.REACT_APP_ENV === 'production' ? prodConfig : devConfig;
};
