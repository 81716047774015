import React, { useEffect, useState } from 'react';
import { Table, Tag, Spin, DatePicker, Button, Tooltip } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import './AllCalls.css';
import { Link } from 'react-router-dom';
import { getAllCalls, GetAllCallsParams } from '../../api/call.api';
import { RoutePath } from '../../constants/routePaths';
import Title from 'antd/es/typography/Title';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { COMPLETE_TEAMS } from '../../config/teamPermissions';

interface CallData {
  id: string;
  identifier: string;
  inputDate: string;
  duration: number;
  score: string;
  status: 'completed' | 'processing' | 'waiting' | 'failed';
  disposition: string;
}

interface PaginationData {
  total: number;
  totalPages: number;
  currentPage: number;
  limit: number;
}

const AllCalls: React.FC = () => {
  const [data, setData] = useState<CallData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredInfo, setFilteredInfo] = useState<Record<string, any>>({});
  const [pagination, setPagination] = useState<PaginationData>({
    total: 0,
    totalPages: 0,
    currentPage: 1,
    limit: 10,
  });
  const [sortInfo, setSortInfo] = useState<{ field: string; order: 'asc' | 'desc' | undefined }>({
    field: 'inputDate',
    order: 'desc',
  });
  const { teamId } = useSelector((state: RootState) => state.persisted.user.value);

  // Get disposition from URL parameters
  const searchParams = new URLSearchParams(window.location.search);
  const dispositionFilter = searchParams.get('disposition');

  console.log('dispositionFilter', dispositionFilter);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const params: GetAllCallsParams = {
        page: pagination.currentPage,
        limit: pagination.limit,
      };

      // Add sorting
      if (sortInfo.field && sortInfo.order) {
        params.sortBy = sortInfo.field as 'score' | 'inputDate';
        params.sortOrder = sortInfo.order;
      }

      // Add filters
      if (filteredInfo.disposition?.length) {
        params.disposition = filteredInfo.disposition[0];
      }
      if (filteredInfo.status?.length) {
        params.processStatus = filteredInfo.status[0];
      }

      const response = await getAllCalls(params);

      const calls = response.data.map((call: any) => ({
        id: call._id,
        identifier: call.identifier,
        inputDate: call.inputDate,
        timestamp: new Date(call.inputDate).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }),
        duration: call.duration,
        score: call.score.toString() || '-',
        status: call.processStatus,
        disposition: call.disposition || 'NEUTRAL',
      }));

      setData(calls);
      setPagination(response.pagination);
    } catch (error) {
      console.error('Error fetching calls:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dispositionFilter) {
      setFilteredInfo({ disposition: [dispositionFilter] });
    }
    fetchData();
  }, [dispositionFilter, pagination.currentPage, pagination.limit, sortInfo, filteredInfo]);

  const handleChange: TableProps<CallData>['onChange'] = (pagination, filters, sorter: any) => {
    // Handle pagination
    setPagination((prev) => ({
      ...prev,
      currentPage: pagination.current || 1,
      limit: pagination.pageSize || 10,
    }));

    // Handle filters
    setFilteredInfo(filters);

    // Handle sorting with three states
    if (sorter.field) {
      setSortInfo({
        field: sorter.field,
        order: sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : undefined,
      });
    } else {
      // Reset sorting when no sort is applied
      setSortInfo({ field: 'inputDate', order: 'desc' });
    }
  };

  const getColumns = (): ColumnsType<CallData> => {
    const baseColumns: ColumnsType<CallData> = [
      {
        title: 'Call',
        dataIndex: 'identifier',
        key: 'identifier',
        width: '30%',
        render: (identifier: string) => (
          // <Tooltip title={identifier}>
          <span>
            {identifier.length > 50 ? (
              <Tooltip title={identifier}>{identifier.slice(0, 50)}...</Tooltip>
            ) : (
              identifier
            )}
          </span>
          // </Tooltip>
        ),
      },
      {
        title: 'Agent Score',
        dataIndex: 'score',
        key: 'score',
        width: '10%',
        sorter: true,
        render: (score: string) => (
          <span>{score === '-' ? '-' : parseFloat(score).toFixed(2) + '%'}</span>
        ),
        sortDirections: ['descend', 'ascend', null],
        sortOrder:
          sortInfo.field === 'score' ? (sortInfo.order === 'asc' ? 'ascend' : 'descend') : null,
      },
    ];

    // Only add disposition column for complete teams
    if (COMPLETE_TEAMS.includes(teamId)) {
      baseColumns.push({
        title: 'Disposition',
        dataIndex: 'disposition',
        key: 'disposition',
        width: '10%',
        filteredValue: filteredInfo.disposition || null,
        render: (disposition: string) => {
          const colorMap = {
            PTP: 'green',
            RTP: 'red',
            CALLBACK: 'blue',
            ALREADY_PAID: 'purple',
            DISPUTE: 'orange',
            NRPC: 'volcano',
            NEUTRAL: 'default',
          };

          const displayText: Record<string, string> = {
            PTP: 'PTP',
            RTP: 'RTP',
            CALLBACK: 'Callback',
            ALREADY_PAID: 'Already Paid',
            DISPUTE: 'Dispute',
            NRPC: 'NRPC',
            NEUTRAL: 'Neutral',
          };

          return (
            <Tag color={colorMap[disposition as keyof typeof colorMap]}>
              {displayText[disposition] || disposition}
            </Tag>
          );
        },
        filters: [
          { text: 'PTP', value: 'PTP' },
          { text: 'RTP', value: 'RTP' },
          { text: 'Callback', value: 'CALLBACK' },
          { text: 'Already Paid', value: 'ALREADY_PAID' },
          { text: 'Dispute', value: 'DISPUTE' },
          { text: 'NRPC', value: 'NRPC' },
          { text: 'Neutral', value: 'NEUTRAL' },
        ],
        onFilter: (value, record) => record.disposition === value,
      });
    }

    // Add remaining columns
    baseColumns.push(
      {
        title: 'Call Date & Time',
        dataIndex: 'inputDate',
        key: 'inputDate',
        width: '20%',
        render: (date: string) => {
          const dateObj = new Date(new Date(date).getTime() - 5.5 * 60 * 60 * 1000);
          const hours = dateObj.getHours();
          const minutes = dateObj.getMinutes();
          const seconds = dateObj.getSeconds();

          const isMiddnight = hours === 0 && minutes === 0 && seconds === 0;

          return dateObj
            .toLocaleString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              ...(isMiddnight
                ? {}
                : {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false,
                  }),
            })
            .replace(',', '');
        },
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        sortOrder:
          sortInfo.field === 'inputDate'
            ? sortInfo.order === 'asc'
              ? 'ascend'
              : 'descend'
            : 'descend',
      },
      {
        title: 'Processing Status',
        dataIndex: 'status',
        key: 'status',
        width: '15%',
        render: (status: string) => {
          const statusColors: Record<string, string> = {
            completed: 'green',
            processing: 'gold',
            waiting: 'blue',
            failed: 'red',
          };
          return <Tag color={statusColors[status.toLowerCase()]}>{status}</Tag>;
        },
        filters: [
          { text: 'Completed', value: 'completed' },
          { text: 'Processing', value: 'processing' },
          { text: 'Waiting', value: 'waiting' },
          { text: 'Failed', value: 'failed' },
        ],
        filteredValue: filteredInfo.status || null,
        onFilter: (value: string, record: CallData) =>
          record.status.toLowerCase() === value.toLowerCase(),
      },
      {
        dataIndex: 'id',
        key: 'id',
        width: '10%',
        render: (id: string, record: CallData) => {
          if (record.status === 'completed') {
            return <Link to={`${RoutePath.CALL_ANALYSIS}?callId=${id}`}>View Call</Link>;
          } else {
            return <span></span>;
          }
        },
      }
    );

    return baseColumns;
  };

  return (
    <div className="main-content">
      <Title level={2}>All Calls</Title>
      <Table
        columns={getColumns()}
        dataSource={data}
        rowKey="id"
        onChange={handleChange}
        loading={isLoading}
        pagination={{
          current: pagination.currentPage,
          pageSize: pagination.limit,
          total: pagination.total,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    </div>
  );
};

export default AllCalls;
