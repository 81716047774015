import { callApi } from '.';

export const processCall = async ({ keys, language }) => {
  return await callApi({
    method: 'POST',
    url: '/call/submit',
    requestBody: { keys, language },
    serializerFunc: (response: any) => response.data,
  });
};

export const processExcel = async ({ excelKey, language }) => {
  return await callApi({
    method: 'POST',
    url: '/call/excel-upload',
    requestBody: { excelKey, language },
    serializerFunc: (response: any) => response.data,
  });
};

export const getCall = async ({ callId }) => {
  return await callApi({
    method: 'GET',
    url: `/call?callId=${callId}`,
    serializerFunc: (response: any) => response.data,
  });
};

export const getAllExcelFiles = async () => {
  return await callApi({
    method: 'GET',
    url: `/call/uploaded-excel-files`,
    serializerFunc: (response: any) => response.data,
  });
};

export const getExcelCalls = async ({ fileKey }) => {
  const params = new URLSearchParams({ excelKey: fileKey });
  return await callApi({
    method: 'GET',
    url: `/call/excel-calls?${params.toString()}`,
    serializerFunc: (response: any) => response.data,
  });
};

export interface GetAllCallsParams {
  page?: number;
  limit?: number;
  sortBy?: 'score' | 'inputDate';
  sortOrder?: 'asc' | 'desc';
  disposition?: 'PTP' | 'RTP' | 'CALLBACK' | 'ALREADY_PAID' | 'DISPUTE' | 'NRPC' | 'NEUTRAL';
  processStatus?: 'completed' | 'failed' | 'processing' | 'waiting';
}

export const getAllCalls = async (params: GetAllCallsParams = {}) => {
  const queryParams = new URLSearchParams();

  // Add page parameter with validation
  const page = Number(params.page);
  if (!isNaN(page) && page > 0) {
    queryParams.append('page', page.toString());
  }

  // Add limit parameter with validation
  const limit = Number(params.limit);
  if (!isNaN(limit) && limit > 0) {
    queryParams.append('limit', limit.toString());
  }

  // Add sorting parameters
  if (params.sortBy && ['score', 'inputDate'].includes(params.sortBy)) {
    queryParams.append('sortBy', params.sortBy);
    if (params.sortOrder && ['asc', 'desc'].includes(params.sortOrder)) {
      queryParams.append('sortOrder', params.sortOrder);
    }
  }

  // Add disposition filter
  if (params.disposition) {
    queryParams.append('disposition', params.disposition);
  }

  // Add processStatus filter
  if (params.processStatus) {
    queryParams.append('processStatus', params.processStatus);
  }

  const queryString = queryParams.toString();

  return await callApi({
    method: 'GET',
    url: `/call/all${queryString ? `?${queryString}` : ''}`,
    serializerFunc: (response: any) => response.data,
  });
};

export const deleteCall = async ({ callId }) => {
  return await callApi({
    method: 'DELETE',
    url: `/call?callId=${callId}`,
    serializerFunc: (response: any) => response.data,
  });
};

export const getDashboardMetrics = async () => {
  return await callApi({
    method: 'GET',
    url: `/call/dashboard-metrics`,
    serializerFunc: (response: any) => response.data,
  });
};

export const validateExcelUpload = async ({ excelKey }) => {
  return await callApi({
    method: 'POST',
    url: `/call/validate-excel-upload`,
    requestBody: { excelKey },
    serializerFunc: (response: any) => response.data,
  });
};
